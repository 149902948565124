<template lang="pug">
  .wrapper
    .content
      section
        img(src="https://oss.woxiaojiang.com/oss/snails/source/license/business-web.png")
      section
        img(src="https://oss.woxiaojiang.com/oss/snails/source/license/food-web.png")
      section
        img(src="https://oss.woxiaojiang.com/oss/snails/source/license/icp-web.png")
      section
        img(src="https://oss.woxiaojiang.com/oss/snails/source/license/site-web.png")
    pageFooter
</template>

<script>
  const pageFooter = () => import('@/components/pageFooter')
  export default {
    components: {
      pageFooter
    }
  }
</script>

<style lang="less" scoped>
  .wrapper {
    position: relative;
    width: 100%;
    margin: 0 auto;
    overflow-y: auto;
    .content {
      padding: .2rem .3rem .1rem;
      width: 100%;
      min-height: 5rem;
      font-size: .11rem;
      section {
        margin-top: .1rem;
        width: 100%;
        img {
          width: 100%;
          border-radius: .03rem;
          box-shadow: 0 3px 0.05rem rgba(0,0,0,.3);
        }
      }
    }
  }
</style>
